import React, {Component} from "react"
import SEO from "utils/Seo"
import Layout from "../../components/Layout/Layout"
import styled from "styled-components"
import {graphql, StaticQuery} from "gatsby"
import {media} from "utils/Media"
import HeadingSpecial from "../../components/shared/HeadingSpecial";
import SnowFlakes from "../../components/shared/SnowFlakes";
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import Paginator from "../../components/shared/Paginator";
import lotc from "../../images/LOtC-QB-logo.jpg"

const pageSlug = "workshops"
const pageTitle = "Workshops"
const categorySlug = "teaching"
const categoryTitle = "teaching"

const Padding = styled.div`
    padding-left: 15px;
    padding-right: 15px;
`
const Content = styled.div`
    position: relative;
    width: 100%;
    padding-bottom: 2rem;
`
const BoxWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto 4rem;
    text-align: center;
    align-items: stretch;
`

const Box = styled.div`
    filter: drop-shadow(0px 0px 12px rgba(0, 49, 153, 0.2));
    background: white;
    padding: 1rem;
    width: 100%;
    margin: 0.5rem auto;

    @media ${media.sm} {
        margin: 1rem;
    }

    @media ${media.md} {
        padding: 2rem;
    }

    h3 {
        font-family: ${props => props.theme.font.family.special};
        color: ${props => props.theme.colors.navy};
        font-size: 24px;
        margin-bottom: 1rem;
        text-align: center;

        @media ${media.sm} {
            font-size: 30px;
            line-height: 35px;
        }

    }

    p {

    }
`

const InnerBox = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    a {
        @media ${media.md} {
            width: calc(50% - 2rem);
        }
    }
`

class Workshops extends Component {
    constructor(props) {
        super(props);

        this.pageData = {
            pageName: "enrichment-materials",
            siteName: "frozeneducation",
            country: "uk",
            region: "emea"
        }
    }

    componentDidMount() {
        // if (typeof window !== 'undefined' && typeof window.utag !== 'undefined') {
        //     // Tealium
        //     window.utag.view(this.pageData)
        // }
    }

    render() {

        return (
            <Layout categorySlug={categorySlug}
                    category={categoryTitle}
                    currentPage={pageTitle}
                    pageName={pageSlug}
                    mainCategorySlug="teaching-resources"
                    enrichmentHeader>
                <SEO slug={pageSlug} title={pageTitle} description=""/>
                <SnowFlakes>
                    <Content className="enrichment">
                        <HeadingSpecial lines={false} headingTag="h1"
                                        className="py-4 py-md-5 font--bigger"
                                        title={pageTitle}/>
                        <Padding>
                        <p className="font--regular narrow text-center mx-auto mb-5">
                            Led by specifically trained Disney teaching artists, workshops can be tailored to
                            your group’s needs and give your students the rare opportunity to perform scenes,
                            songs and dances from the FROZEN whilst developing core skills such as communication
                            and team building in a uniques and creative way.</p>
                        <p className="font--regular narrow text-center mx-auto mb-5">
                            Workshops cost £14 per person on top of the ticket price and last 90 minutes. In
                            London, they take place in a venue near the Theatre Royal Drury Lane. They are
                            suitable for those aged 7 and up; minimum group size is 20.
                        </p>
                            <Paginator/>
                        </Padding>
                        <BoxWrapper>
                            <Box>
                                <p className="font-weight-bold narrow text-center mx-auto">To book or to
                                    find out more information:</p>
                                <InnerBox>
                                    <a href="tel:+448006408101"
                                       className="btn btn--new no-interstitial-check d-flex mx-auto text-uppercase text-white my-3">
                                        <span>CALL 0800 640 8101</span>
                                    </a>
                                    <a href="https://groupbooking.disneytickets.co.uk/contact/frozen-workshop/?prevPageName=emea:uk:disney:frozenthemusical:teaching-resources:workshops&adobe_mc=MCMID%3D19459291422493953404049622713115713592%7CMCORGID%3DCC0A3704532E6FD70A490D44%2540AdobeOrg%7CTS%3D1627385703"
                                       className="btn btn--new no-interstitial-check d-flex mx-auto text-uppercase text-white my-3">
                                        <span>EMAIL ENQUIRY</span>
                                    </a>
                                </InnerBox>

                                <p className="font-weight-bold narrow text-center mt-3 mb-0 mx-auto">or email <a href="mailto:groupsales@disneytickets.co.uk">groupsales@disneytickets.co.uk</a> </p>
                                <img style={{width: "160px"}} className="d-block mt-4 mb-0 mx-auto" src={lotc} alt="Quality badge" title="Quality badge"/>
                            </Box>
                        </BoxWrapper>
                        <GatsbyImage placeholder="none" image={getImage(this.props.data.TopImageLg)} alt="" title=""
                             className="img-fluid d-none d-lg-block"/>
                        <GatsbyImage placeholder="none" image={getImage(this.props.data.TopImageMd)} alt="" title=""
                             className="img-fluid d-none d-sm-block d-lg-none "/>
                        <GatsbyImage placeholder="none" image={getImage(this.props.data.TopImageXs)} alt="" title=""
                             className="img-fluid d-block d-sm-none"/>
                    </Content>
                </SnowFlakes>
            </Layout>
        )
    }
}

const Query = (props) => (
    <StaticQuery
        query={graphql`
            query {
                TopImageLg: file(relativePath: { eq: "TeachingResources/header-workshops-1920.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                TopImageMd: file(relativePath: { eq: "TeachingResources/header-workshops-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                TopImageXs: file(relativePath: { eq: "TeachingResources/header-workshops-576.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
            }
		`}
        render={data => (
            <Workshops data={data}/>
        )}
    />
)

export default Query
